import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import '../scss/main.scss';

document.addEventListener('DOMContentLoaded', init);

function init() {
  swperVideoCarousel();
}

function swperVideoCarousel() {
  new Swiper('.swiper', {
    modules: [Navigation, Pagination],
    loop: true,
    slidesPerView: 1,
    longSwipes: true,
    allowSlidePrev: true,
    allowSlideNext: true,
    direction: 'horizontal',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
}
